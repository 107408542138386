import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { IActionMap, ObjectTabType, TechnicalTabType } from "core/models";
import { DefaultJumpMenuSelectedItem, ITab } from "core/models";
import * as fromActions from "../actions";

export interface ITabState extends EntityState<ITab> {
  selectedTab: string;
}

export const tabAdapter: EntityAdapter<ITab> = createEntityAdapter<ITab>();

export const initialState: ITabState = Object.assign(
  tabAdapter.getInitialState(),
  {
    selectedTab: "",
  }
);

const actionMap: IActionMap<ITabState, fromActions.TabActions> = {
  [fromActions.ADD_MANY_TABS]: addManyTabsHandler,
  [fromActions.ADD_TAB_SUCCESS]: addTabHandler,
  [fromActions.REMOVE_TAB_SUCCESS]: removeTabHandler,
  [fromActions.SET_SELECTED_TAB]: setSelectedTab,
  [fromActions.UPDATE_IS_DIRTY_IN_TAB]: updateDirtyTabHandler,
  [fromActions.UPDATE_JUMP_MENU_SELECTED_ITEM_IN_TAB]: updateJumpMenuSelectedItemInTabHandler,
  [fromActions.UPDATE_JUMP_MENU_SELECTED_ITEM_IN_TAB_AND_SET_ACTIVE]: updateJumpMenuSelectedItemInTabAndSetActiveHandler,
};

export function reducer(
  state: ITabState = initialState,
  action: fromActions.TabActions
): ITabState {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }
  return state;
}

function addManyTabsHandler(state: ITabState,
  action: fromActions.AddManyTabs
): ITabState {
  return tabAdapter.addMany(action.payload.tabs, { ...state, selectedTab: action.payload.activeTabId });
}


function addTabHandler(
  state: ITabState,
  action: fromActions.AddTab
): ITabState {
  let jumpMenuSelectedItem = action.payload.jumpMenuSelectedItem
    ? action.payload.jumpMenuSelectedItem
    : DefaultJumpMenuSelectedItem;
  let jumpTabSelectedItem = action.payload.jumpTabSelectedItem
    ? action.payload.jumpTabSelectedItem
    : DefaultJumpMenuSelectedItem;
  const updatedCurrentTabIndexState = {
    ...state,
    selectedTab: action.payload.id,
  };
  return tabAdapter.addOne(
    {
      id: action.payload.id,
      type: action.payload.type,
      objectType: action.payload.objectType,
      jumpMenuSelectedItem: jumpMenuSelectedItem,
      jumpTabSelectedItem: jumpTabSelectedItem,
      isDirty: false,
      tag: action.payload.tag,
      metadata: action.payload.metadata
    },
    updatedCurrentTabIndexState
  );
}

function removeTabHandler(
  state: ITabState,
  action: fromActions.RemoveTabSuccess
): ITabState {
  state = {
    ...state,
    selectedTab: action.payload.selectedTab,
  };
  return tabAdapter.removeOne(action.payload.id, state);
}

function setSelectedTab(
  state: ITabState,
  action: fromActions.SetSelectedTab
): ITabState {
  const updatedCurrentTabIndexState = {
    ...state,
    selectedTab: action.payload.id,
  };
  return tabAdapter.updateOne(
    {
      id: action.payload.id,
      changes: {
        isDirty: false
      },
    },
    updatedCurrentTabIndexState
  );
}

function updateDirtyTabHandler(
  state: ITabState,
  action: fromActions.UpdateIsDirtyInTab
): ITabState {
  const updatedCurrentTabIndexState = {
    ...state
  };
  return tabAdapter.updateOne(
    {
      id: action.payload.id,
      changes: {
        isDirty: action.payload.isDirty
      },
    },
    updatedCurrentTabIndexState
  );
}

function updateJumpMenuSelectedItemInTabHandler(
  state: ITabState,
  action: fromActions.UpdateJumpMenuSelectedItemInTab
): ITabState {
  if (action.payload.jumpTabSelectedItem) {
    if (!action.payload.jumpMenuSelectedItem) {
      return tabAdapter.updateOne(
        {
          id: action.payload.id,
          changes: {
            jumpTabSelectedItem: action.payload.jumpTabSelectedItem,
          },
        },
        state
      );
    } else {
      return tabAdapter.updateOne(
        {
          id: action.payload.id,
          changes: {
            jumpMenuSelectedItem: action.payload.jumpMenuSelectedItem,
            jumpTabSelectedItem: action.payload.jumpTabSelectedItem,
          },
        },
        state
      );
    }
  } else {
    return tabAdapter.updateOne(
      {
        id: action.payload.id,
        changes: {
          jumpMenuSelectedItem: action.payload.jumpMenuSelectedItem,
        },
      },
      state
    );
  }
}

function updateJumpMenuSelectedItemInTabAndSetActiveHandler(
  state: ITabState,
  action: fromActions.UpdateJumpMenuSelectedItemInTabAndActiveTab
): ITabState {
  const updatedCurrentTabIndexState = {
    ...state,
    selectedTab: action.payload.id,
  };
  return tabAdapter.updateOne(
    {
      id: action.payload.id,
      changes: {
        jumpMenuSelectedItem: action.payload.jumpMenuSelectedItem,
        jumpTabSelectedItem: action.payload.jumpTabSelectedItem,
        isDirty: false
      },
    },
    updatedCurrentTabIndexState
  );
}


export const { selectAll, selectEntities } = tabAdapter.getSelectors();
export const selectCurrentTab = (state: ITabState) => state.selectedTab;
export const selectCurrentEntity = (state: ITabState) => state.entities[state.selectedTab];
