import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NbDialogRef } from "@nebular/theme";
import { Actions, ofType } from '@ngrx/effects';
import { Store } from "@ngrx/store";
import { MessageService } from "core/services";
import { setFormAvailability } from 'core/utilities';
import { Subscription } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "core-add-object",
  template: "",
})
export class AddObjectComponent implements OnDestroy {
  public formGroup: FormGroup;
  public addAction: any;
  public addActionSuccess: any;
  public addActionFailure: any;
  public selectedTag: { id: string, parentTagId: string }
  relationObjectKey: any;
  relationObject: any;
  limitError: boolean = false;
  set isLoadingAddAction(value: boolean) {
    this._isLoadingAddAction = value;
  }
  get isLoadingAddAction(): boolean {
    return this._isLoadingAddAction;
  }
  private _isLoadingAddAction: boolean = false;
  isDisabled: boolean = false;
  subAddSuccessAction: Subscription;
  subAddFailureAction: Subscription;
  constructor(
    private store: Store<unknown>,
    private dialogRef: NbDialogRef<unknown>,
    private actionsSubj$?: Actions,
    private messageService?: MessageService,

  ) {

  }
  ngOnDestroy() {
    if (this.subAddSuccessAction) {
      this.subAddSuccessAction.unsubscribe();
    }
    if (this.subAddFailureAction) {
      this.subAddFailureAction.unsubscribe();
    }
  }
  addObject(): void {
    if (this.formGroup.valid) {
      this.isLoadingAddAction = true;
      this.isDisabled = true;
      setFormAvailability(this.formGroup, this.isDisabled);
      this.store.dispatch(new this.addAction(this.formGroup.value));
      if (!this.addActionSuccess) {
        this.dialogRef.close();
      }
      if (this.addActionSuccess) {
        this.subAddSuccessAction = this.actionsSubj$.pipe(
          ofType(this.addActionSuccess)
        ).subscribe(data => {
          this.isLoadingAddAction = false;
          this.dialogRef.close();
          this.isDisabled = false;
          setFormAvailability(this.formGroup, this.isDisabled)
        });
      }
      if (this.addActionFailure) {
        this.subAddFailureAction = this.actionsSubj$.pipe(
          ofType(this.addActionFailure)
        ).subscribe(data => {
          this.isLoadingAddAction = false;
          this.isDisabled = false;
          setFormAvailability(this.formGroup, this.isDisabled)
          if (data.payload.error.userMessage === 'Failed to create user because number of user to be created for an account has exceed') {
            // this.messageService.showError(data.payload, 'Number of user has exceeded!!');
            this.limitError = true;
          } else if (data.payload) {
            this.messageService.showError(data.payload, 'Error!!')
            this.dialogRef.close();
          }
        });
      }
    }
  }

  addObjectWithTag(): void {
    if (this.formGroup.valid) {
      this.isLoadingAddAction = true;
      this.isDisabled = true;
      setFormAvailability(this.formGroup, this.isDisabled);
      this.store.dispatch(new this.addAction({ body: { ...this.formGroup.value }, relationObjectKey: this.relationObjectKey, relationObject: this.relationObject }, this.selectedTag));
      if (!this.addActionSuccess) {
        this.dialogRef.close();
      }
      if (this.addActionSuccess) {
        this.subAddSuccessAction = this.actionsSubj$.pipe(
          ofType(this.addActionSuccess)
        ).subscribe(data => {
          this.isLoadingAddAction = false;
          this.dialogRef.close();
          this.isDisabled = false;
          setFormAvailability(this.formGroup, this.isDisabled)
        });
      }
      if (this.addActionFailure) {
        this.subAddFailureAction = this.actionsSubj$.pipe(
          ofType(this.addActionFailure)
        ).subscribe(data => {
          this.isLoadingAddAction = false;
          this.isDisabled = false;
          setFormAvailability(this.formGroup, this.isDisabled)
        });
      }
    }
  }
}
