import { EnvName, IdentityManagement } from './config.model';
import { IConfigMap } from './configMap';

export const demoConfig: IConfigMap = {
    environment: EnvName.DEMO,
    name: EnvName.DEMO,
    proxyUri: '',
    rootUri: 'https://demo.api.decisionsfirst.com/',
    authBaseUrl: 'https://demo.api.decisionsfirst.com/ims/',
    identityManagement: IdentityManagement.INAPP,
};